import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../App";

import Checkbox from "../../components/Checkbox";

import "./Terms.scss";

interface TermsProps {
    goNext: () => void;
}

const Terms: React.FC<TermsProps> = ({ goNext }) => {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptRodo, setAcceptRodo] = useState(false);
    return (
        <div className="terms">
            <Checkbox value={acceptTerms} changeValue={v => setAcceptTerms(v)}>
                Przeczytałem/am <Link to={ROUTES.Terms}>regulamin sklepu</Link> i akceptuję jego postanowienia.
            </Checkbox>

            <Checkbox value={acceptRodo} changeValue={v => setAcceptRodo(v)}>
                Wyrażam zgodę na przetwarzanie moich danych osobowych w ramach procesu realizacji zamówienia. Dane przetwarzane są tylko i
                wyłącznie podczas realizacji zamówienia. Administratorem danych jest firma Venn Krzysztof Gaik z siedzibą przy ulicy
                Gromadzkiej 46, 30-719 Kraków.
            </Checkbox>

            <div className="terms__button">
                <button className="button" disabled={!acceptRodo || !acceptTerms} onClick={() => submit()}>
                    Zamawiam
                </button>
            </div>
        </div>
    );

    function submit() {
        if (acceptTerms && acceptRodo) {
            goNext();
        }
    }
};

export default Terms;
