import React from "react";

const Rodo: React.FC = () => {
    return (
        <div className="regulamin">
            <div className="regulamin__center">
                <div className="regulamin__header">
                    Klauzula przetwarzania danych osobowych udostępnionych drogą elektroniczną <br />
                    <br />
                </div>
                <div className="regulamin__text">
                    <b>TOŻSAMOŚĆ ADMINISTRATORA</b> <br />
                    Administratorem danych jest Venn Krzysztof Gaik, mający siedzibę w Warszawie (02-681) przy al. Wyścigowej 14 lok. 463 –
                    odpowiada za utrzymanie i rozwój rejestru. <br />
                    <br />
                    <b>DANE KONTAKTOWE ADMINISTRATORA</b> <br />
                    Z administratorem danych można się skontaktować poprzez adres email rodo@venn.pl lub pisemnie na adres siedziby
                    administratora.
                    <br />
                    <br />
                    <b>DANE KONTAKTOWE INSPEKTORA OCHRONY DANYCH</b> <br />
                    Inspektor ochrony danych, z którym może się Pani / Pan skontaktować poprzez email rodo@venn.pl. Z inspektorem ochrony
                    danych można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw
                    związanych z przetwarzaniem danych.
                    <br />
                    <br />
                    <b>CELE PRZETWARZANIA I PODSTAWA PRAWNA</b> <br />
                    Strony w domenie http://www.poradnik-inzyniera-elektryka.pl/ mogą zawierać adresy skrzynek mailowych oraz formularze,
                    wymagające wypełnienia danych osobowych, umożliwiające zakupy w sklepie internetowym. Użytkownik składający zamówienie
                    poprzez sklep internetowy zgadza się na przetwarzanie jego danych (adres e-mail, imię, nazwisko, numer telefonu, adres
                    dostawy oraz inne podane podczas składania zamówienia) w celu realizacji złożonego zamówienia. <br />
                    <br />
                    <b>ODBIORCY DANYCH</b> <br />
                    Pani / Pana dane osobowe mogą być udostępniane i przekazywane organom administracji publicznej, służbom, sądom i
                    prokuraturze.
                    <br />
                    <br />
                    <b>PRZEKAZANIE DANYCH OSOBOWYCH DO PAŃSTWA TRZECIEGO LUB ORGANIZACJI MIĘDZYNARODOWEJ</b> <br />
                    Nie dotyczy. <br />
                    <br />
                    <b>OKRES PRZECHOWYWANIA DANYCH</b> <br />
                    Pani / Pana dane będą przetwarzane czasowo, tj. na potrzeby realizacji zamówienia. <br />
                    <br />
                    <b>PRAWA PODMIOTÓW DANYCH</b> <br />
                    Przysługuje Pani/Panu prawo dostępu do Pani/Pana danych oraz prawo żądania ich sprostowania i usunięcia. <br />
                    <br />
                    <b>PRAWO WNIESIENIA SKARGI DO ORGANU NADZORCZEGO</b> <br />
                    Przysługuje Pani/Panu prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w państwie
                    członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia. Biuro Prezesa
                    Urzędu Ochrony Danych Osobowych (PUODO) Adres: Stawki 2, 00-193 Warszawa Telefon: 22 531 03 00 <br />
                    <br />
                    <b>ŹRÓDŁO POCHODZENIA DANYCH OSOBOWYCH</b> <br />
                    Dane pochodzą od osób których dane dotyczą i zostały udostępnione celem realizacji zamówienia w sklepie internetowym.
                    <br />
                    <br />
                    <b>INFORMACJA O DOWOLNOŚCI LUB OBOWIĄZKU PODANIA DANYCH</b> <br />
                    Podanie danych osobowych było dobrowolne ale warunkowało możliwość podjęcia działań przez administratora danych. <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Rodo;
